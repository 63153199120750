import React from 'react';

export default function GrantCycleOpen() {
    return (
        <>
            <span className="info-block__subtitle">
                Community Gift Card Requests
            </span>
            <p className="info-block__copy--small">
                Gift card donations support local 501c3 organizations in our
                operating communities that align with our focus areas and are
                within a 30 mile radius of our locations.
                <ul className="indent">
                    <li>
                        We accept one application per organization annually. All
                        requests must be submitted through the online
                        application.
                    </li>
                    <li>
                        Funding requests are accepted January 15th through
                        September 1st.
                    </li>
                    <li>
                        Allow up to 6 weeks for review and decision. If
                        additional information is needed, you will be contacted
                        directly.
                    </li>
                </ul>
            </p>
            <a
                href="https://apply.yourcausegrants.com/apply/programs/1791f964-6ebb-427d-8bf9-0945b58fe8d9"
                target="_blank"
                rel="noreferrer noopener"
            >
                APPLY FOR GIFT CARDS
            </a>
        </>
    );
}
