import styled from 'styled-components';

const InfoBlockStyled = styled.section`
    display: flex;
    flex-direction: ${props =>
        props.imageAlignment === 'left' ? 'row' : 'row-reverse'};
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;

    img {
        border-radius: 10px;
        border: 2px solid #fff;

        @media screen and (max-width: 1024px) {
            width: 326px;
            height: auto;
        }
    }

    .info-block__content {
        display: flex;
        flex-direction: column;
        margin: ${props =>
            props.imageAlignment === 'left' ? '0 0 0 64px' : '0 64px 0 0'};
        width: 90%;
        max-width: 531px;

        .info-block__title {
            font-size: 38px;
            font-weight: 700;
            font-style: italic;
            text-transform: uppercase;
            letter-spacing: 2px;

            @media screen and (max-width: 1024px) {
                font-size: 28px;
            }
        }

        .info-block__copy {
            margin: 20px 0;
            font: 400 16px roboto, sans-serif;
            line-height: 32px;
            color: #6c7279;

            @media screen and (max-width: 1024px) {
                font-size: 14px;
                line-height: 30px;
            }
        }
        .info-block__indent{
            padding-left:5em;
        }

        @media screen and (max-width: 1024px) {
            width: 100%;
            max-width: 326px;

            margin: 25px 0 56px 0;
        }
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

export default InfoBlockStyled;
