import styled from 'styled-components';

const InfoBlockStyled = styled.section`
    display: flex;
    flex-direction: ${props =>
        props.imageAlignment === 'left' ? 'row' : 'row-reverse'};
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;

    img {
        border-radius: 10px;
        border: 2px solid #fff;
        width: 514px;
        height: auto;

        @media screen and (max-width: 1024px) {
            width: 326px;
            height: auto;
        }
    }

    .info-block__content {
        display: flex;
        flex-direction: column;
        margin: ${props =>
            props.imageAlignment === 'left' ? '0 0 0 64px' : '0 64px 0 0'};
        width: 90%;
        max-width: 531px;

        .info-block__title,
        .info-block__subtitle {
            font-size: 38px;
            font-weight: 700;
            font-style: italic;
            text-transform: uppercase;
            letter-spacing: 2px;

            @media screen and (max-width: 1024px) {
                font-size: 28px;
            }
        }

        .info-block__subtitle {
            font-size: 24px;
            letter-spacing: 1.8px;
        }

        .info-block__copy,
        .info-block__copy--small {
            margin: 20px 0;
            font: 400 16px roboto, sans-serif;
            line-height: 32px;
            color: #6c7279;

            @media screen and (max-width: 1024px) {
                font-size: 14px;
                line-height: 30px;
            }
        }

        .info-block__copy--small {
            margin-top: 8px;
            font-size: 14px;
            line-height: 26px;
        }

        a {
            margin-bottom: 32px;
            font-size: 19px;
            font-weight: 700;
            font-style: italic;
            color: #0b1822;

            &:hover {
                text-decoration: none;
            }
        }

        ul.indent {
            padding-left:34px;
        }

        @media screen and (max-width: 1024px) {
            width: 100%;
            max-width: 326px;

            margin: 25px 0 0 0;
        }
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

export default InfoBlockStyled;
