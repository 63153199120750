import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../button';

import InfoBlockStyled from './infoBlockStyled';

class InfoBlock extends Component {
    constructor() {
        super();
        this.state = {
            browserWidth: window.innerWidth,
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        this.setState({ browserWidth: window.innerWidth });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        return (
            <InfoBlockStyled
                className={this.props.className ? this.props.className : ''}
                imageAlignment={this.props.imageAlignment}
            >
                <img
                    src={
                        this.state.browserWidth <= 1024 &&
                        this.props.mobileImageSource
                            ? this.props.mobileImageSource
                            : this.props.imageSource
                    }
                    alt={this.props.imageAlt}
                />
                <section className="info-block__content">
                    <span className="info-block__title">
                        {this.props.title}
                    </span>
                    {this.props.copy.map((paragraph, index) => (
                        <p className="info-block__copy" key={index}>
                            {paragraph}
                        </p>
                    ))}
                    {this.props.link ? (
                        <Button
                            buttonText={this.props.buttonText}
                            buttonType="primary"
                            isExternal={this.props.linkIsExternal}
                            link={this.props.link}
                        />
                    ) : null}
                </section>
            </InfoBlockStyled>
        );
    }
}

InfoBlock.propTypes = {
    buttonText: PropTypes.string,
    copy: PropTypes.array.isRequired,
    imageAlignment: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    imageSource: PropTypes.node.isRequired,
    mobileImageSource: PropTypes.node,
    link: PropTypes.string,
    linkIsExternal: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
};

InfoBlock.defaultProps = {
    imageAlignment: 'left',
    imageAlt: '',
    linkIsExternal: false,
};

export default InfoBlock;
