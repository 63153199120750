/********* UTILITIES *********/
import React, { Component } from 'react';
import SEO from '../components/seo';
import { withSize } from 'react-sizeme';

/********* STYLES *********/
import AboutStyled from '../components/page-styles/about-styles';

/********* COMPONENTS *********/
import Blurb from '../components/blurb';
import GivingBack from '../components/givingBack/givingBack';
import Hero from '../components/hero';
import InfoBlock from '../components/infoBlock/infoBlock';
import InfoCard from '../components/infoCard';
import Layout from '../components/layout';

/********* IMAGES *********/
import JoinOurTeam from '../images/about-join-our-team.png';
import JoinOurTeamMobile from '../images/about-join-our-team-mobile.png';
import OurHistory from '../images/our-history.png';
import OurHistoryMobile from '../images/our-history--mobile.png';
import OurPurpose from '../images/about-life-at-pilot.png';
import heroImageDesktop from '../images/hero-about-desktop.png';

class AboutPage extends Component {
    componentDidMount() {}

    render() {
        const { path, size } = this.props;
        return (
            <Layout path={path}>
                <AboutStyled>
                    <SEO
                        title="Pilot Company began in Gate City & helps the trucking industry"
                        description="Pilot Company was founded in Gate City, VA, in 1954 & has supported the trucking industry for over 60 years, before being bought by Berkshire Hathaway in 2023"
                    />
                    <Hero
                        headerText="About Pilot Company"
                        background={
                            size.width > 768
                                ? heroImageDesktop
                                : heroImageDesktop
                        }
                        showLogo={false}
                        isSubPage={true}
                    ></Hero>
                    <InfoBlock
                        className="our-history"
                        copy={[
                            'It started in 1958 with a single gas station in Gate City, Virginia. James A. Haslam II wanted to build a business to support his growing family and to provide people with the gas and conveniences they need while on the road. In 1981, with 100 convenience stores, Pilot opened its first full-size travel center in Corbin, Kentucky.',
                            'In 2001, we joined with Marathon Ashland Petroleum LLC to form Pilot Travel Centers LLC, operating a nationwide network of 232 travel centers. By 2009, our company had grown to be the 17th largest U.S. franchisee of fast food restaurants as we continued to grow our travel center network and offer a great variety of amenities.',
                            'In 2010, Pilot merged with Flying J to form a combined network of more than 550 travel centers. We also launched our first mobile app to provide drivers with route planning, finding our locations and features to save time and money on the road. We have continued to invest in developing innovative technology, growing our network and upgrading our travel center locations for an enhanced guest experience.',
                            'In 2017, we began focusing on building our supply chain by scaling our energy and logistics capabilities. On October 3, 2017, we were proud to become part of the Berkshire Hathaway portfolio with Berkshire making a significant minority investment in our company. Today, Pilot Company is the 7th largest privately-held company in America.',
                        ]}
                        imageAlt="Original Pilot filling station sign with gas prices"
                        imageSource={OurHistory}
                        mobileImageSource={OurHistoryMobile}
                        title="Our History"
                    />
                    <section className="our-purpose info">
                        <Blurb
                            title="Our Purpose"
                            copy={[
                                "We are dedicated to fueling life's journeys and helping our people thrive – whether that is serving motorists and professional drivers, delivering fuel, giving back to our communities, or supporting a team member's career aspirations. We strive to fulfill this mission with intent to make a better day for our team members and guests.",
                            ]}
                        />
                        <InfoBlock
                            buttonType="primary"
                            buttonText="Explore Careers"
                            className="life-at-pilot"
                            copy={[
                                'We are passionate about our people-first culture. Like a family, we foster a culture of trust, integrity and respect while we work together and work hard to get results. Our team members are supported by our active leadership team, mentoring programs, professional development classes and a collaborative work environment. We proudly offer competitive weekly pay, 401(k), paid time off, parental leave, comprehensive medical plans and wellness programs to fuel the growth and future of our team members during their journey with Pilot.',
                            ]}
                            imageAlt="Pilot employee smiling while posting a commendation card to employee bulletin board"
                            imageSource={OurPurpose}
                            link="http://jobs.pilotflyingj.com"
                            linkIsExternal
                            title="Life At Pilot"
                        />
                        <a id="giving" name="giving"></a>
                        <GivingBack />
                        <div className="focused-giving">
                            <span>We Focus Our Giving To Support:</span>
                            <div className="info-cards">
                                <InfoCard
                                    title="Education"
                                    copy="We give back to organizations that inspire students to excel in their studies through specialized programs, providing equal access to technology and fostering opportunities for innovation."
                                />
                                <InfoCard
                                    title="Veterans"
                                    copy="We give back to organizations that support veterans and their families through focused efforts on education and employment, aiming to fuel the future success of United States military members as they transition back to civilian life."
                                />
                                <InfoCard
                                    title="Safety"
                                    copy="We give back to organizations that provide critical services to promote safety in our communities and on the road."
                                />
                            </div>
                        </div>
                    </section>
                    <section className="join-our-team">
                        <InfoBlock
                            buttonText="Explore Careers"
                            copy={[
                                'Fuel your future with a growing and innovative company. We are committed to investing in our team members and developing passionate leaders that strive to help our company and communities thrive. Team members have the opportunity to work hard and have fun while also advancing professionally. ',
                                'We are looking for more incredible people to join our team.',
                                'Learn more about our great benefits and open positions.',
                            ]}
                            link="http://jobs.pilotflyingj.com"
                            linkIsExternal
                            imageAlt="Picture of Pilot Company at work in a travel center"
                            imageSource={JoinOurTeam}
                            mobileImageSource={JoinOurTeamMobile}
                            title="Join Our Team"
                        />
                    </section>
                </AboutStyled>
            </Layout>
        );
    }
}

export default withSize()(AboutPage);
