import styled from 'styled-components';

const AboutStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    .our-history {
        margin: 48px 40px 12px;

        @media screen and (max-width: 768px) {
            position: relative;
            margin-top: -31px;
            z-index: 8000;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 64px 0 0 0;

        .info__intro {
            margin: 72px 0 64px;
        }
    }

    .our-purpose {
        background: linear-gradient(
            180deg,
            rgba(228, 226, 219, 1) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        width: 100%;
    }

    .giving-back {
        margin-top: 64px;

        @media screen and (max-width: 1024px) {
            margin-top: 0px;
        }
    }

    .philanthropy-opportunites {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px 0 40px 0;

        .external-link {
            margin: 10px;
        }

        @media screen and (max-width: 1024px) {
            margin-top: 0;
        }
    }

    .focused-giving {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        span {
            margin: 48px 0;
            font-size: 30px;
            font-weight: 700;
            font-style: italic;
            text-transform: uppercase;
        }

        .info-cards {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            aside {
                width: 348px;
                height: 302px;
            }
        }
    }

    .join-our-team {
        display: flex;
        justify-content: center;
        margin-top: 56px;
        margin-bottom: 70px;
    }
`;

export default AboutStyled;
